
$(document).ready(function(){

    var $stats = $('.stats');
    var popover_open = false;

    if($stats.length > 0){

        // Selector de fechas
        var $custom_range = $('.custom-range');
        var template_popover = $('<form />', {

            id : 'form-period',
            action : $custom_range.data('url'),
            method : 'get',
            append : function(){

                var $btn_close = $('<span href="#" class="close-btn-popover"><i class="fa fa-times" aria-hidden="true"></i></span>');
                $btn_close.on('click', function(){
                    $custom_range.popover('hide');
                });

                return [
                    $('<label class="pull-left">' +
                            '<small>De</small> ' +
                            '<input type="text" class="datepicker" id="period_start" name="period_start" required="required" value="" autocomplete="off"> ' +
                            '<a href="#" class="clear-inputs hidden" title="Borrar fecha"><small>Borrar</small></a>' +
                        '</label>'+
                        '<label class="pull-right">' +
                            '<small>Hasta</small> ' +
                            '<input type="text" class="datepicker" id="period_end" name="period_end" required="required" value="" autocomplete="off"> ' +
                            '<a href="#" class="clear-inputs hidden" title="Borrar fecha"><small>Borrar</small></a>' +
                        '</label>'+
                        '<button type="submit" class="btn btn-success btn-sm"><span>Mostrar</span></button>'),

                    $btn_close
                ];

            }

        });

        var startDate,
            endDate,
            startPicker,
            endPicker,
            updateStartDate = function() {
                startPicker.setStartRange(startDate);
                endPicker.setStartRange(startDate);
                endPicker.setMinDate(startDate);
            },
            updateEndDate = function() {
                startPicker.setEndRange(endDate);
                startPicker.setMaxDate(endDate);
                endPicker.setEndRange(endDate);
            };

        $custom_range.on('shown.bs.popover', function () {

                var $start_input = $('.popover-content').find('#period_start');
                var $end_input = $('.popover-content').find('#period_end');

                if(typeof startPicker == 'undefined' && typeof endPicker == 'undefined'){

                    startPicker = new Pikaday({
                        field: $start_input[0],
                        //minDate: new Date(),
                        //maxDate: new Date(2020, 12, 31),
                        position: 'bottom left',
                        theme: 'triangle-theme',
                        i18n: crm_config.pikaday_i18n_config,
                        onSelect: function() {
                            startDate = this.getDate();
                            updateStartDate();
                        },
                        onOpen: function() {

                            var position = $start_input.offset();

                            $('.pika-single').css({
                                left: (position.left - $('.pika-single').width()) + $start_input.width()
                            });
                        }
                    });
                    endPicker = new Pikaday({
                        field: $end_input[0],
                        //minDate: new Date(),
                        //maxDate: new Date(2020, 12, 31),
                        position: 'bottom left',
                        theme: 'triangle-theme',
                        i18n: crm_config.pikaday_i18n_config,
                        onSelect: function() {
                            endDate = this.getDate();
                            updateEndDate();
                        },
                        onOpen: function() {

                            var position = $end_input.offset();

                            $('.pika-single').css({
                                left: (position.left - $('.pika-single').width()) + $end_input.width()
                            });
                        }
                    });

                    startPicker.getDate();
                    endPicker.getDate();

                    updateStartDate();
                    updateEndDate();

                }
        });

        $custom_range.popover({

            placement : 'bottom',
            html: true,
            content: template_popover,
            trigger: 'click'

        });

        $custom_range.on('click', function(e){
            e.preventDefault();
            $custom_range.popover('toggle');
        });


        // ======================= Gráfica de Pie =======================

        var $main_stats = $('.main-stats');
        var check = typeof data_chart  == 'object' ? (data_chart.length === undefined) : !(data_chart.length === 0);

        if($main_stats.length > 0 && check){

            var ctx1 = $('#chart');

            var pie_chart = new Chart(ctx1, {
                type: 'pie',
                data: {
                    labels: data_chart.labels,
                    datasets: [{
                        data: data_chart.data,
                        total_amount_won: data_chart.total_amount_won,
                        percent: data_chart.percent,
                        backgroundColor: data_chart.colors
                    }]
                },
                options: {
                    responsive: true,
                    legend: false,
                    legendCallback: function(chart) {

                        var text = [];
                        var datasets = chart.config.data.datasets[0];

                        text.push('<ul class="' + chart.id + '-legend">');

                        for (var i = 0; i < datasets.data.length; i++) {

                            text.push('<li><span class="item-color" style="background-color:' + datasets.backgroundColor[i] + ' !important;"></span>');

                            if (chart.data.labels[i]) {
                                text.push('<span class="lead">'+ datasets.percent[i] + '% ' + chart.data.labels[i] + ' ($'+datasets.total_amount_won[i] + ') </span>');
                            }

                            text.push('</li>');
                        }

                        text.push('</ul>');

                        return text.join("");

                    }
                }
            });

            $("#chartjs-legend").html(pie_chart.generateLegend());

        }


        if($main_stats.length > 0){

            var ctx2 = $('#chart-deals-by-source');

            var pie_chart2 = new Chart(ctx2, {
                type: 'pie',
                data: {
                    labels: data_chart_source.labels,
                    datasets: [{
                        data: data_chart_source.data,
                        backgroundColor: data_chart_source.colors
                    }]
                },
                options: {
                    responsive: true,
                    legend: false,
                    legendCallback: function(chart) {

                        var text = [];
                        var datasets = chart.config.data.datasets[0];

                        text.push('<ul class="' + chart.id + '-legend">');

                        text.push('<li><span class="lead" style="font-size: 16px; font-weight: bold;">Fuente</span></li>');

                        for (var i = 0; i < datasets.data.length; i++) {

                            text.push('<li><span class="item-color" style="background-color:' + datasets.backgroundColor[i] + ' !important;"></span>');

                            if (chart.data.labels[i]) {
                                text.push('<span class="lead">' + chart.data.labels[i] + '</span>');
                            }

                            text.push('</li>');
                        }

                        text.push('</ul>');

                        return text.join("");

                    }
                }
            });

            $("#chartjs-legend-deals-by-source").html(pie_chart2.generateLegend());


            var ctx3 = $('#chart-deals-by-segment');

            var pie_chart3 = new Chart(ctx3, {
                type: 'pie',
                data: {
                    labels: data_chart_segment.labels,
                    datasets: [{
                        data: data_chart_segment.data,
                        backgroundColor: data_chart_segment.colors
                    }]
                },
                options: {
                    responsive: true,
                    legend: false,
                    legendCallback: function(chart) {

                        var text = [];
                        var datasets = chart.config.data.datasets[0];

                        text.push('<ul class="' + chart.id + '-legend">');

                        text.push('<li><span class="lead" style="font-size: 16px; font-weight: bold;">Segmento</span></li>');

                        for (var i = 0; i < datasets.data.length; i++) {

                            text.push('<li><span class="item-color" style="background-color:' + datasets.backgroundColor[i] + ' !important;"></span>');

                            if (chart.data.labels[i]) {
                                text.push('<span class="lead">' + chart.data.labels[i] + '</span>');
                            }

                            text.push('</li>');
                        }

                        text.push('</ul>');

                        return text.join("");

                    }
                }
            });

            $("#chartjs-legend-deals-by-segment").html(pie_chart3.generateLegend());


        }


        // ======================= Gráfica de barras =======================

        var $stage_stats = $('.stage-stats');

        if($stage_stats.length > 0){

            var ctx4 = $('#chart');

            Chart.defaults.global.legend.display = false;

            var bar_chart = new Chart(ctx4, {
                type: 'bar',
                data: {
                    label: false,
                    labels: data_chart.labels,
                    display: false,
                    datasets: [{
                        data: data_chart.data,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.8)',
                            'rgba(54, 162, 235, 0.8)',
                            'rgba(255, 206, 86, 0.8)',
                            'rgba(75, 192, 192, 0.8)',
                            'rgba(153, 102, 255, 0.8)',
                            'rgba(255, 159, 64, 0.8)'
                        ]
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                stepSize: 1
                            }
                        }]
                    }
                }
            });

        }

    }


});