$(document).ready(function(){

    var $rates = $('.rates');
    var $form_hotel = $('.form-hotel');

    if($rates.length > 0){

        var $date_start_input = $('#date_start');
        var $date_end_input = $('#date_end');
        var startDate;
        var endDate;

        var updateStartDate = function() {
            startPicker.setStartRange(startDate);
            endPicker.setStartRange(startDate);
            endPicker.setMinDate(startDate);
        };

        var updateEndDate = function() {
            startPicker.setEndRange(endDate);
            startPicker.setMaxDate(endDate);
            endPicker.setEndRange(endDate);
        };

        var startPicker = new Pikaday({
            field: $date_start_input[0],
            format: 'D/MM',
            position: 'bottom left',
            theme: 'triangle-theme',
            i18n: crm_config.pikaday_i18n_config,
            onSelect: function() {
                startDate = this.getDate();
                updateStartDate();
                $('input[name=start]').val(moment(startDate).format('YYYY-MM-DD'));
            },
            onOpen: function() {

                var position = $date_start_input.offset();

                $('.pika-single').css({
                    top: (position.top + 40)
                });
            }
        });

        var endPicker = new Pikaday({
            field: $date_end_input[0],
            format: 'D/MM',
            position: 'bottom left',
            theme: 'triangle-theme',
            i18n: crm_config.pikaday_i18n_config,
            onSelect: function() {
                endDate = this.getDate();
                updateEndDate();

                $('input[name=end]').val(moment(endDate).format('YYYY-MM-DD'));
            },
            onOpen: function() {

                var position = $date_end_input.offset();

                $('.pika-single').css({
                    top: (position.top + 40)
                });
            }
        });

        startPicker.getDate();
        endPicker.getDate();

        updateStartDate();
        updateEndDate();

        var $label = $('#label');
        var $commission = $('#commission');
        var $commision_na = $('#commission_na');

        $('#has_date_range').on('change', function() {

            if($(this).is(":checked")) {

                $date_start_input.prop('disabled', false);
                $date_end_input.prop('disabled', false);
                $label.val('').prop('disabled', true);

                return;
            }

            $label.val('').prop('disabled', false);
            $date_start_input.val('').prop('disabled', true);
            $date_end_input.val('').prop('disabled', true);
            $('input[name=start]').val('');
            $('input[name=end]').val('');

        });

        $commision_na.on('change', function(){

            if($(this).is(":checked")) {

                $commission.prop('disabled', true);

                return;
            }

            $commission.prop('disabled', false);
        });

    }

    if($form_hotel.length > 0){

        $('#hotel_name').on('change', function(){

            console.log('Change!!');

        });

    }

});