$(document).ready(function(){

    // =================== Embudos ===================
    var $pipes = $('.pipe');
    var $deals_main_container = $('.deals-container');

    if($pipes.length > 0){

        $pipes.height($(window).height() - 100);
        $deals_main_container.height($(window).height() - 165);

        $(window).on('resize', function(e){
            $pipes.height($(this).height() - 100);
            $deals_main_container.height($(window).height() - 165);
        });

        var sortables = [];
        var $actions_bar = $('.actions-bar');
        var $actions_bar_trash = $('#actions-bar-trash');
        var $actions_bar_lost = $('#actions-bar-lost');
        var $actions_bar_won = $('#actions-bar-won');
        var current_stage = {};
        var last_stage = {};

        var props = {
            group: 'pipes',
            filter: '.disabled',
            sort: false,
            disabled: false,
            animation: 0,
            ghostClass: 'sortable-ghost',
            dragClass: 'sortable-drag',
            scroll: false,

            onStart: function (evt) {
                $actions_bar.addClass('up');
                last_stage = $(evt.item).parent().data('stage');
            },

            onMove: function (evt,originalEvent) {

                colorizeCol.clear();
                colorizeCol.colorize(evt.to);
            },

            onEnd: function(evt){

                var $item = $(evt.item);
                current_stage = $item.parent().data('stage');

                updateStatusDeal.updateHeader(current_stage);
                updateStatusDeal.updateHeader(last_stage);

                if($item.parent().data('isstage') != true){
                    $item.css('opacity', 0);
                    setTimeout(function(){
                        $item.remove();
                    }, 0.6)
                }

                colorizeCol.clear();
                updateStatusDeal.update(evt.item, evt.to);

                setTimeout(function(){
                    $actions_bar.removeClass('up');
                }, 200);

                for(var i = 0; i<sortables.length; i++){
                    var order = sortables[i].toArray().sort(function (a, b) {
                        return b - a;
                    });
                    sortables[i].sort(order);
                }
            }

        };

        Sortable.create($actions_bar_trash[0], props);
        Sortable.create($actions_bar_lost[0], props);
        Sortable.create($actions_bar_won[0], props);

        var colorizeCol = {

            clear : function () {
                $('.deals-container').removeClass('selected');
                $('.actions-bar .action').removeClass('active');
            },

            colorize : function(obj){

                var $obj = $(obj);

                if($obj.data('isstage')){
                    $(obj).addClass('selected');
                }else{
                    $(obj).addClass('active');
                }

            }

        };

        var updateStatusDeal = {

            update : function(item, target){

                var $item = $(item);
                var item_id = $item.data('id');
                //var stage = $(target).data('stage');
                var $parent = $(item).parent();
                var stage = $(item).parent().data('stage'); // Fix hasta que reparen el bug: https://github.com/RubaXa/Sortable/issues/1027
                var data = {};

                if($parent.data('isstage')){
                    data = {
                        id: item_id,
                        stage: stage
                    }
                }else{
                    data = {
                        id: item_id,
                        action: $parent.data('action')
                    };
                }

                $.post('negocios/updatestatus', data, function(res){
                    console.log(res);

                });

            },

            updateHeader: function(stage){

                if(typeof stage != 'undefined'){

                    var $deals_container = $('#deals-container-'+stage);
                    var $parent_deals_container = $deals_container.parent();
                    var $deals = $deals_container.find('li');
                    var count_deals = $deals.length;
                    var $pipe_header = $parent_deals_container.find('.pipe-header');
                    var total = 0;

                    if(count_deals > 0){

                        $deals.each(function (i, obj) {

                            total += parseInt($(this).data('value'));

                        });

                        $pipe_header.find('.updatable').addClass('show');
                        $pipe_header.find('.total').text(accounting.formatMoney(total,'$',0));
                        $pipe_header.find('.deals.num').text(count_deals);

                    }else{

                        $pipe_header.find('.updatable').removeClass('show');
                    }

                }

            }

        };

        for(var i = 1; i<=6; i++){
            var $deals_container = $('#deals-container-' + i);
            updateStatusDeal.updateHeader(i);
            sortables.push( Sortable.create($deals_container[0], props) );
        }


        var $deals_filter = $('.deals-filter');
        var filter_selected = $deals_filter.data('selected');
        var $filter_user = $deals_filter.find('#user-select').select2({
            closeOnSelect: false,
            dropdownParent: $('.dropdown-user li'),
            width: '245px',
            language: "es",
            theme: "bootstrap",
            placeholder: 'Select an option',
            containerCssClass: ':all:'
        }).on("select2:closing", function(e) {
            e.preventDefault();
        }).on("select2:closed", function(e) {
            $filter_user.select2("open");
        }).on('select2:select', function(e){

            if($(this).val() == 'all'){
                window.location = base_url + '/negocios';
            }else{
                window.location = base_url + '/negocios?filter=' + $(this).val();
            }

        });

        $filter_user.select2("open");

    }

});