$(document).ready(function(){

    // =================== Config Select2 ===================
    $.fn.select2.defaults.set( "theme", "bootstrap" );

    $('.custom-select').select2( {
        width: null,
        containerCssClass: ':all:',
        language: "es"
    } );

    // =================== jQuery-Autocomplete ===================

    var autocomplete_inputs = function(opts){

        if(opts.input.length > 0){

            var url = opts.input.data('url');

            opts.input.autocomplete({
                serviceUrl: url,
                autoSelectFirst: true,
                showNoSuggestionNotice: true,
                minChars: 2,
                noSuggestionNotice: '',
                onSelect: function (suggestion) {

                    opts.id_field.val(suggestion.data);

                    if(typeof opts.new_item_field !== 'undefined'){

                        opts.input.attr('name', '');

                    }

                },
                onSearchComplete: function(query, suggestions){

                    if(typeof opts.new_item_field !== 'undefined'){

                        opts.input.attr('name',opts.new_item_field);

                        if(suggestions.length == 0){

                            var txt = opts.no_suggestion_notice.replace(/\{input\}/g, opts.input.val());

                            $('.autocomplete-no-suggestion').text(txt);

                            opts.input.attr('value', query);
                            opts.id_field.val('');
                        }

                    }

                }
            });

        }

    };

    // Contactos
    autocomplete_inputs({
        input: $('#contact'),
        id_field: $('input[name=contact_id]'),
        new_item_field: 'new_contact',
        no_suggestion_notice: '\'{input}\' se añadirá como nuevo contacto'
    });

    // Organización
    autocomplete_inputs({
        input: $('#organization'),
        id_field: $('input[name=organization_id]'),
        new_item_field: 'new_organization',
        no_suggestion_notice: '\'{input}\' se añadirá como nueva organización'
    });

    // Negocio
    autocomplete_inputs({
        input: $('#deal'),
        id_field: $('input[name=deal_id]')
    });

    // Hoteles
    autocomplete_inputs({
        input: $('#hotel_name'),
        id_field: $('input[name=hotel_id]'),
        new_item_field: 'new_hotel',
        no_suggestion_notice: '\'{input}\' se añadirá como nuevo hotel'
    });

    // Destination
    autocomplete_inputs({
        input: $('#destination_name'),
        id_field: $('input[name=destination_id]'),
        new_item_field: 'new_destination',
        no_suggestion_notice: '\'{input}\' se añadirá como nuevo destino'
    });


});