$(document).ready(function () {

    var $body = $('body');
    var $activities = $('.activities');
    var $activity_actions = $('.activity-actions');

    if($activities.length > 0){

        if($activity_actions.length > 0){

            var $activity_form = $('.activity-form');
            var $activity_form_data = $activity_form.data();
            var action = $activity_form_data.action;
            var event_id =  $activity_form_data.evt_id;
            var event_added = false;
            var $due_date = $('#due_date');
            var $due_time = $('#due_time');
            var $duration = $('#duration');
            var evt_fc_to_picker = false;

            // ============ Tipos de actividad ============

            var $act_name = $('#name');
            var $types = $('.activity-types');

            $types.find('[data-toggle="tooltip"]').tooltip({ container: 'body' });

            $types.find('label').on('click', function(){
                $types.find('.active').removeClass('active');
                $(this).addClass('active');
                $act_name.attr('placeholder', $(this).data('original-title'));

                utils.updateEvent(event_id, {
                    title: utils.eventTitle()
                });

            });

            // ============ Actualizar título del evento ============

            $act_name.on('keyup change', function(){

                if($(this).val() == ''){

                    utils.updateEvent(event_id, {
                        title: $(this).attr('placeholder')
                    });

                }else{

                    utils.updateEvent(event_id, {
                        title: $(this).val()
                    });
                }

            });

            // ============ CKEditor config ============

            var ck_config = {
                height: '85px',
                toolbarGroups : [
                    { name: 'document',	   groups: [ 'mode', 'document', 'doctools' ] },
                    { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
                    { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
                    { name: 'forms' },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                    { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
                    { name: 'links' },
                    { name: 'insert' },
                    { name: 'styles' },
                    { name: 'colors' },
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' }
                ],
                removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Underline,Strike,Subscript,Superscript',
                removeDialogTabs: 'link:advanced',
                toolbarLocation: 'bottom'

            };
            $('#activity-content').ckeditor(function(){},ck_config);


            // ============ Timepicker config ============

            $due_time.timepicker({
                'timeFormat' : 'H:i',
                'step': 15
            }).on('changeTime', function() {

                var duration = $duration.val() != '' ? moment.duration($duration.val()).asMinutes() : 30;
                var time = $(this).val() != '' ? $(this).val() : moment().minutes(0).format('HH:mm');
                var date = $calendar.fullCalendar('getDate');
                var start = moment(date.format('YYYY-MM-DD') + ' ' + time );

                utils.updateEvent(event_id, {
                    start: start,
                    end: start.clone().add(duration, 'minutes')
                });

                utils.scrollToFirstEvent();

            });

            $duration.timepicker({
                'minTime': '00:00',
                'maxTime': '8:00',
                'step': 15,
                'lang': {},
                'timeFormat' : 'H:i'
            }).on('changeTime', function() {

                var duration = moment.duration($(this).val()).asMinutes();
                var event = utils.findEvent(event_id);

                utils.updateEvent(event_id, {
                    end: event.start.clone().add(duration, 'minutes')
                });

            });

            // ============ Pikaday config ============

            var picker = new Pikaday({

                field: $due_date[0],
                minDate: moment(),
                i18n: crm_config.pikaday_i18n_config,
                format: 'D MMM YYYY',
                theme: 'triangle-theme',
                onSelect: function(date){

                    if(evt_fc_to_picker){
                        $calendar.fullCalendar( 'gotoDate', date );
                        evt_fc_to_picker = false;
                    }else{
                        $calendar.fullCalendar( 'gotoDate', date );
                    }

                    $('input[name=due_date]').val(moment(date).format('YYYY-MM-DD'));

                }

            });

            $due_date.on('change', function(){ evt_fc_to_picker = false; });


            // ============ Fullcalendar ============

            var $calendar = $('#calendar');

            $body.on('click', 'button.fc-prev-button, button.fc-next-button', function() {
                picker.setDate($calendar.fullCalendar('getDate').format());
                evt_fc_to_picker = true;
            });

            var utils = {

                findEvent: function(id){
                    var event = $calendar.fullCalendar( 'clientEvents', id );
                    return event[0];
                },

                addEvent: function(event){

                    $calendar.fullCalendar('renderEvent', event, true);

                },

                updateEvent: function(id, event){

                    var evt = this.findEvent(id);
                    evt.title = this.eventTitle();

                    var tmp = $.extend(evt, event);
                    $calendar.fullCalendar('updateEvent', tmp);

                    utils.scrollToFirstEvent();

                },

                removeEvent: function(id){

                    $calendar.fullCalendar('removeEvents', id);

                },

                createEvent: function(obj){

                    this.removeEvent(event_id);

                    var start = moment(moment(obj.start).format('YYYY-MM-DD') + ' ' + moment().minutes(0).format('HH:mm:ss'));
                    var duration = $duration.val() != '' ? moment.duration($duration.val()).asMinutes() : 30;
                    var end = start.clone().add(duration, 'minutes');

                    this.addEvent({

                        id: event_id,
                        title: this.eventTitle(),
                        start: start,
                        end: end,
                        is_new: true

                    });

                    var event = this.findEvent(event_id);

                    // Al crear el evento definimos los valores de los inputs relacionados a la fecha/hora
                    picker.setDate($calendar.fullCalendar('getDate').format());
                    $due_time.val(event.start.clone().format('HH:mm'));
                    $duration.timepicker('setTime', this.calcDuration(event));

                },

                calcDuration: function(event){

                    var ms = event.end.diff(event.start);
                    var d = moment.duration(ms);

                    return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

                },

                timeToMinutes : function(time){

                    return moment.duration(time).asMinutes();

                },

                eventTitle: function(){

                    return $act_name.val() == '' ? $types.find('.active').data('original-title') : $act_name.val();

                },

                scrollToFirstEvent: function(){

                    var offset = -45;

                    if($('.fc-time-grid-event').length > 0){

                        var renderedEvents = $('div.fc-event-container a.fc-draggable');
                        var firstEventOffsetTop = renderedEvents && renderedEvents.length > 0 ? renderedEvents[0].offsetTop : 0;

                        $('div.fc-scroller').scrollTo(firstEventOffsetTop + (offset) +'px');

                    }
                }

            };

            $calendar.fullCalendar({
                header: {
                    left: 'title',
                    center: '',
                    right: 'prev,next'
                },
                defaultDate: $activity_form_data.evt_date != '' ? moment($activity_form_data.evt_date) : moment().subtract(1, 'day').format(),
                defaultView: 'agendaDay',
                editable: true,
                allDaySlot: false,
                nowIndicator: true,
                //eventOverlap: false,
                height: 'parent',
                locale: 'es',
                slotLabelFormat: 'HH:mm',
                slotDuration: '00:15:00',
                scrollTime: '00:00:00',
                timezone: 'America/Mexico_City',
                eventDragStart: function(event, jsEvent, ui, view){},

                eventDrop: function(event, delta, revertFunc, jsEvent, ui, view){

                    //console.log(delta);
                    //console.log(jsEvent);
                    //console.log(ui);
                    //console.log(view);

                    $due_time.val(event.start.clone().format('HH:mm'));

                },
                eventResize: function(event, delta, revertFunc) {

                    $duration.timepicker('setTime', utils.calcDuration(event));

                },
                eventOverlap: function(stillEvent, movingEvent) {
                    return false;
                },
                viewRender: function(view, element) {
                    //console.log("The view's title is " + view.intervalStart.format());
                    //console.log("The view's title is " + view.name);

                    var duration = null;
                    var time = null;
                    var start = null;

                    // Si no está añadido el evento, añadimos uno nuevo
                    if(!event_added){

                        // Si la acción actual es crear, solo añadimos uno nuevo
                        if($activity_form_data.action == 'create'){

                            picker.setDate($calendar.fullCalendar('getDate').add(1,'day').format());

                            utils.createEvent({
                                start: view.intervalStart
                            });

                            // Si la acción actual es editar, creamos un nuevo evento con los info de los 'datas'
                            // de $activity_form
                        }else{

                            picker.setDate($calendar.fullCalendar('getDate').format());

                            /*       duration = $activity_form_data.evt_duration != '' ? moment.duration($activity_form_data.evt_duration).asMinutes() : 30;
                             time = $activity_form_data.evt_time != '' ? $activity_form_data.evt_time : moment().format('HH:mm');
                             start = moment($calendar.fullCalendar('getDate').format('YYYY-MM-DD') + ' ' + time);

                             utils.addEvent({
                             id: event_id,
                             title: utils.eventTitle(),
                             start: start,
                             end: start.clone().add(duration, 'minutes')
                             });*/

                        }

                        event_added = true;

                    }else{

                        duration = $duration.val() != '' ? moment.duration($duration.val()).asMinutes() : 30;
                        time = $due_time.val() != '' ? $due_time.val() : moment().format('HH:mm');
                        start = moment(view.intervalStart.format('YYYY-MM-DD') + ' ' + time );

                        if(view.intervalStart.format('YYYY-MM-DD') != moment($activity_form_data.evt_date).format('YYYY-MM-DD')){

                            utils.removeEvent(event_id);

                            utils.addEvent({
                                id: event_id,
                                title: utils.eventTitle(),
                                start: start,
                                end: start.clone().add(duration, 'minutes')
                            });

                        }
                    }

                    setTimeout(function(){
                        utils.scrollToFirstEvent();
                    }, 300);

                },
                events: function(start, end, timezone, callback){

                    var url = $activity_form_data.url;

                    $.get(url, function(events){
                        callback(events);
                    });

                }
            });

            $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                $calendar.fullCalendar('render');
            });

            /*    utils.addEvent({

             id: event_id,
             title: $('#name').val() != '' ? $('#name').val() : 'Nuevo evento',
             start: moment(),
             end: moment().add(30, 'minutes')

             });*/

        } // End if

        // ================== Filtros ==================

        var $filter = $('#filter');
        var $type_act = $('#type_act');
        var $date_filters = $('.date-filters');
        var $type_filters = $('.type-filters');
        var table = $('#activities-table').DataTable();

        $type_filters.find('[data-toggle="tooltip"]').tooltip({ container: 'body' });

        $type_filters.find('a').on('click', function(e){
            e.preventDefault();

            $type_filters.find('.active').removeClass('active');
            $(this).addClass('active');

            $type_act.val($(this).data('filter'));

            table.ajax.reload();

        });

        $date_filters.find('a').on('click', function(e){
            e.preventDefault();

            $date_filters.find('.active').removeClass('active');
            $(this).addClass('active');

            $filter.val($(this).data('filter'));

            if(!$(this).hasClass('custom-range')){
                table.ajax.reload();
            }
        });

        // ================== Selector de fechas ==================

        var $custom_range = $('.custom-range');
        var template_popover = $('<form />', {

            id : 'form-period',
            append : function(){

                var $btn_close = $('<span href="#" class="close-btn-popover"><i class="fa fa-times" aria-hidden="true"></i></span>');
                $btn_close.on('click', function(){
                    $custom_range.popover('hide');
                });

                return [
                    $('<label class="pull-left">' +
                        '<small>De</small> ' +
                        '<input type="text" class="datepicker" id="period_start" name="period_start" required="required" value="" autocomplete="off"> ' +
                        '<a href="#" class="clear-inputs hidden" title="Borrar fecha"><small>Borrar</small></a>' +
                        '</label>'+
                        '<label class="pull-right">' +
                        '<small>Hasta</small> ' +
                        '<input type="text" class="datepicker" id="period_end" name="period_end" required="required" value="" autocomplete="off"> ' +
                        '<a href="#" class="clear-inputs hidden" title="Borrar fecha"><small>Borrar</small></a>' +
                        '</label>'+
                        '<button type="submit" class="btn btn-success btn-sm" id="btn-filter-by-range"><span>Mostrar</span></button>'),

                    $btn_close
                ];

            }

        });

        var startDate,
            endDate,
            startPicker,
            endPicker,
            updateStartDate = function() {
                startPicker.setStartRange(startDate);
                endPicker.setStartRange(startDate);
                endPicker.setMinDate(startDate);
                $('#range').val(moment(startDate).format('YYYY-MM-DD')+','+moment(endDate).format('YYYY-MM-DD'));
            },
            updateEndDate = function() {
                startPicker.setEndRange(endDate);
                startPicker.setMaxDate(endDate);
                endPicker.setEndRange(endDate);
                $('#range').val(moment(startDate).format('YYYY-MM-DD')+','+moment(endDate).format('YYYY-MM-DD'));
            };

        $custom_range.on('shown.bs.popover', function () {

            var $start_input = $('.popover-content').find('#period_start');
            var $end_input = $('.popover-content').find('#period_end');

            if(typeof startPicker == 'undefined' && typeof endPicker == 'undefined'){

                startPicker = new Pikaday({
                    field: $start_input[0],
                    //minDate: new Date(),
                    //maxDate: new Date(2020, 12, 31),
                    position: 'bottom left',
                    theme: 'triangle-theme',
                    i18n: crm_config.pikaday_i18n_config,
                    onSelect: function() {
                        startDate = this.getDate();
                        updateStartDate();
                    },
                    onOpen: function() {

                        var position = $start_input.offset();

                        $('.pika-single').css({
                            left: (position.left - $('.pika-single').width()) + $start_input.width()
                        });
                    }
                });
                endPicker = new Pikaday({
                    field: $end_input[0],
                    //minDate: new Date(),
                    //maxDate: new Date(2020, 12, 31),
                    position: 'bottom left',
                    theme: 'triangle-theme',
                    i18n: crm_config.pikaday_i18n_config,
                    onSelect: function() {
                        endDate = this.getDate();
                        updateEndDate();
                    },
                    onOpen: function() {

                        var position = $end_input.offset();

                        $('.pika-single').css({
                            left: (position.left - $('.pika-single').width()) + $end_input.width()
                        });
                    }
                });

                startPicker.getDate();
                endPicker.getDate();

                updateStartDate();
                updateEndDate();

            }
        });

        $custom_range.popover({

            placement : 'bottom',
            html: true,
            content: template_popover,
            trigger: 'click'

        });

        $custom_range.on('click', function(e){
            e.preventDefault();
            $custom_range.popover('toggle');
        });

        $body.on('click','#btn-filter-by-range', function(e){

            e.preventDefault();
            table.ajax.reload();

            $custom_range.popover('hide');

        });

    } // End if

});
