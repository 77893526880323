$(document).ready(function(){

    // =================== Embudos ===================
    var $pipes = $('.pipe');
    var $forecast_col = $('.forecast-col');

    if($forecast_col.length > 0){

        var sortables = [];
        var num_cols = 4;
        var $headers = $('.col-header');
        var $actions_bar = $('.actions-bar');
        var $actions_bar_trash = $('#actions-bar-trash');
        var $actions_bar_lost = $('#actions-bar-lost');
        var $actions_bar_won = $('#actions-bar-won');
        var visibility = [];
        var last_item = {};
        var current_stage = 0;
        var last_stage = 0;
        var today = moment();
        var opts = {
            lines: 13 // The number of lines to draw
            , length: 20 // The length of each line
            , width: 14 // The line thickness
            , radius: 42 // The radius of the inner circle
            , scale: 0.15 // Scales overall size of the spinner
            , corners: 1 // Corner roundness (0..1)
            , color: '#000' // #rgb or #rrggbb or array of colors
            , opacity: 0.25 // Opacity of the lines
            , rotate: 0 // The rotation offset
            , direction: 1 // 1: clockwise, -1: counterclockwise
            , speed: 1 // Rounds per second
            , trail: 60 // Afterglow percentage
            , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
            , zIndex: 2e9 // The z-index (defaults to 2000000000)
            , className: 'spinner' // The CSS class to assign to the spinner
            , top: '50%' // Top position relative to parent
            , left: '50%' // Left position relative to parent
            , shadow: false // Whether to render a shadow
            , hwaccel: false // Whether to use hardware acceleration
            , position: 'relative' // Element positioning
        };
        var spinner_container = $('.spinner-container')[0];
        var spinner = new Spinner(opts);
        var $datepicker = $('.datepicker');
        var picker = new Pikaday(
            {
                field: $('#date')[0],
                //minDate: new Date(),
                reposition: false,
                bound: false,
                i18n: crm_config.pikaday_i18n_config,
                theme: 'triangle-theme',
                container: $datepicker.find('.calendar')[0],
                onSelect: function(date){

                    $datepicker.hide();

                    updateStatusDeal.update(last_item, null);

                    if(!utils.is_visible(date)){
                        last_item.remove();
                    }

                },
                onClose: function(){

                    $datepicker.hide();
                }
            });

        $forecast_col.height($(window).height() - 100);

        $(window).on('resize', function(e){

            $forecast_col.height($(this).height() - 100);

        });

        $(document).on('click', function(e){

            var test = false;

            $(e.target).parents().each(function(){

                if($(this).is('.datepicker'))
                    test = true;

            });

            if(!test && $datepicker.is(':visible')){
                $datepicker.hide();
            }
        });

        $headers.each(function(){
            visibility.push($(this).data('date'));
        });

        var props = {
            group: 'sortable-cols',
            filter: '.disabled',
            sort: false,
            disabled: false,
            animation: 0,
            ghostClass: 'sortable-ghost',
            dragClass: 'sortable-drag',
            scroll: false,

            onStart: function (evt) {
                $actions_bar.addClass('up');
                last_stage = $(evt.item).parent().data('id');
            },

            onMove: function (evt,originalEvent) {

                colorizeCol.clear();
                colorizeCol.colorize(evt.to);

            },

            onEnd: function(evt){

                var $item = $(evt.item);
                last_item = $item;
                var position = $item.offset();
                var $pickdate = $('.pika-single');

                if($item.parent().hasClass('action')){

                    $item.css('opacity', 0);

                    setTimeout(function(){
                        updateStatusDeal.update(evt.item, evt.to);
                        $item.remove();
                        updateStatusDeal.updateHeader(last_stage);
                    }, 0.6);

                }else{

                    if(current_stage != last_stage){

                        picker.setDate($item.parent().data('date'));
                        $datepicker.css('display', 'block');
                        current_stage = $item.parent().data('id');
                        updateStatusDeal.updateHeader(current_stage);
                        updateStatusDeal.updateHeader(last_stage);
                        $datepicker.css({
                            top: position.top - 10,
                            left: position.left + 10
                        });

                        current_stage = last_stage = 0;
                    }
                }

                colorizeCol.clear();

                setTimeout(function(){
                    $actions_bar.removeClass('up');
                }, 200);

                for(var i = 0; i<sortables.length; i++){
                    var order = sortables[i].toArray().sort(function (a, b) {
                        return b - a;
                    });
                    sortables[i].sort(order);
                }
            }

        };

        var colorizeCol = {

            clear : function () {
                $('.deals-container').removeClass('selected');
                $('.actions-bar .action').removeClass('active');
            },

            colorize : function(obj){

                var $obj = $(obj);

                if($obj.data('isstage')){
                    $(obj).addClass('selected');
                }else{
                    $(obj).addClass('active');
                }

            }

        };

        var updateStatusDeal = {

            update : function(item, target){

                var $item = $(item);

                var item_id = $item.data('id');
                //var stage = $(target).data('stage');
                var $parent = $(item).parent();
                var stage = $(item).parent().data('stage'); // Fix hasta que reparen el bug: https://github.com/RubaXa/Sortable/issues/1027
                var data = {};
                var refresh = false;

                if($parent.data('action')){

                    if($parent.data('action') == 'won'){

                        data = {
                            id: item_id,
                            action: $parent.data('action'),
                            new_date: $parent.data('date')
                        };

                        refresh = true;

                    }else{

                        data = {
                            id: item_id,
                            action: $parent.data('action')
                        };

                    }

                }else{

                    data = {
                        id: item_id,
                        status: $item.data('status'),
                        new_date: $('#date').val()
                    }

                }

                $.post(base_url + '/negocios/updatestatus', data, function(res){

                    //console.log(res);

                    if(refresh){
                        //location.reload();
                        controls_actions.reset();
                    }

                });

                if($item.data('section') == 'pipes'){ }

                else if($item.data('section') == 'forecast'){ }

            },

            updateHeader: function(stage){

                var section = $('.deals').data('section');
                var $deals_container = $('#deals-container-'+stage);
                var $parent_deals_container = $deals_container.parent();
                var $deals = $deals_container.find('li');
                var count_deals = $deals.length;
                var total = 0;
                var total_won = 0;
                var $summary =  $parent_deals_container.find('.summary');

                if(count_deals > 0){ }

                $deals.each(function (i, obj) {

                    if($(this).data('status') == 'open'){
                        total_won += parseInt($(this).data('value'));
                    }else{
                        total += parseInt($(this).data('value'));
                    }

                });

                $summary.find('li:eq(0)').text(accounting.formatMoney(total_won,'$',0));
                $summary.find('li:eq(1)').text(accounting.formatMoney(total,'$',0));
                $summary.find('li:eq(2)').text(accounting.formatMoney(total+total_won,'$',0));

            },

            setZeroHeaders: function(){

                for(var i = 1; i<=num_cols; i++){

                    var section = $('.deals').data('section');
                    var $deals_container = $('#deals-container-'+i);
                    var $parent_deals_container = $deals_container.parent();
                    var $summary =  $parent_deals_container.find('.summary');

                    $summary.find('li:eq(0)').text(accounting.formatMoney(0,'$',0));
                    $summary.find('li:eq(1)').text(accounting.formatMoney(0,'$',0));
                    $summary.find('li:eq(2)').text(accounting.formatMoney(0,'$',0));

                }

            }

        };

        var $controls = $('.controls');

        $('#previousMore').on('click', function(e){

            e.preventDefault();
            controls_actions.previousMore();

        });

        $('#previous').on('click', function(e){

            e.preventDefault();
            controls_actions.previous();

        });

        $('#reset').on('click', function(e){

            e.preventDefault();
            controls_actions.reset();

        });

        $('#next').on('click', function(e){

            e.preventDefault();
            controls_actions.next();

        });

        $('#nextMore').on('click', function(e){

            e.preventDefault();
            controls_actions.nextMore();

        });


        var controls_actions = {

            previousMore : function(){
                update_columns.update_headers('sub', 4);
            },

            previous: function(){
                update_columns.update_headers('sub', 1);
            },

            next: function(){
                update_columns.update_headers('add', 1);
            },

            nextMore: function(){
                update_columns.update_headers('add', 4);
            },

            reset: function(){

                //update_columns.update_headers('reset');

                var new_date = today.clone();
                var server_calls = [];
                visibility = [];

                utils.spinner.show();
                utils.controls.disable();

                $headers.each(function(k, v){

                    if(k > 0){
                        new_date = date_operations.add(new_date, 1).locale('es');
                    }

                    var tmp = new_date.add(1, 'days').format('YYYY-MM-DD');

                    $(this).data('date', tmp);
                    $(this).attr('data-date', tmp);
                    $(this).parent().find('.deals-container').data('date', tmp);
                    $(this).parent().find('.deals-container').attr('data-date', tmp);
                    $(this).find('.name').text(new_date.locale('es').format('MMMM'));

                    server_calls.push(update_columns.get_info_cols($(this), new_date.format('YYYY-MM-DD')));

                    visibility.push(tmp);
                });

                utils.update_url('');

                $.when.apply($, server_calls).then(function (data) {
                    update_columns.update_cols();
                    utils.spinner.hide();
                    utils.controls.enable();
                });

            }
        };

        var update_columns = {

            update_headers : function(action, step){

                var format_name = 'MMMM';
                var new_date = null;
                var self = this;
                var server_calls = [];
                visibility = [];

                utils.spinner.show();
                utils.controls.disable();

                $headers.each(function(k, v){

                    var date = moment($(this).data('date'));

                    new_date = action == 'add' ? date_operations.add(date, step) : date_operations.sub(date, step);

                    var tmp = new_date.add(1, 'days').format('YYYY-MM-DD');

                    $(this).data('date', tmp);
                    $(this).attr('data-date', tmp);
                    $(this).parent().find('.deals-container').data('date', tmp);
                    $(this).parent().find('.deals-container').attr('data-date', tmp);

                    if(today.format('YYYY') != new_date.format('YYYY')){
                        format_name = 'MMMM YYYY';
                    }

                    $(this).find('.name').text(new_date.locale('es').format(format_name));

                    server_calls.push(self.get_info_cols($(this), new_date.format('YYYY-MM-DD')));

                    visibility.push(tmp);

                });

                utils.update_url(visibility[0]);

                $.when.apply($, server_calls).then(function (data) {
                    self.update_cols();
                    utils.spinner.hide();
                    utils.controls.enable();
                });

            },

            get_info_cols : function($item, date){

                var self = this;
                var $deals_container = $item.parent().find('.deals-container');

                $deals_container.empty();
                updateStatusDeal.setZeroHeaders();

                return $.get(base_url + '/negocios/get-deals', { date: date },

                    function(data){

                        $.each(data.data, function(i, obj){

                            var item = self.template(obj);

                            $('ul[data-date='+obj.query+']').append( item );
                            /*setTimeout(function(){
                                item.removeClass('item-hidden');
                            }, 200);*/

                        });

                    }
                );

            },

            template : function(obj){

                // <li />
                return $('<li />', {

                    'data-section': 'forecast',
                    'data-id': obj.id,
                    'data-value': obj.value,
                    'data-status' : obj.status,
                    class: 'item-hidden',
                    append : function(){

                        // <a />
                        return $('<a />', {

                            href: base_url + '/actividades/deal/'+obj.id,
                            class: 'deal ' + obj.status,
                            append: function(){

                                // <strong />
                                // <small />
                                // <div />
                                return [

                                    $('<strong />', {

                                        class: 'title',
                                        text: obj.title

                                    }),

                                    $('<small />', {

                                        class: 'text-muted',
                                        append: function(){

                                            // <span />
                                            return '<span>' + accounting.formatMoney(obj.value,'$',0) + '</span>&nbsp;<span>' + obj.organization + '</span>'

                                        }

                                    }),

                                    $('<div />', {

                                        class: 'info',
                                        append: function(){

                                            return obj.status == 'won' ? '<span>Ganado</span>' : '';

                                        }

                                    })

                                ]

                            }


                        });

                    }

                });
            },

            update_cols : function(){

                $('.item-hidden').removeClass('item-hidden');

                for(var i = 1; i<=num_cols; i++){
                    updateStatusDeal.updateHeader(i);
                }

            }

        };

        var date_operations = {

            add : function(date, amount){
                return date.add(amount, 'months').startOf('month')
            },

            sub: function(date, amount){
                return date.subtract(amount, 'months').startOf('month');
            }

        };

        var utils = {

            is_visible: function(date){

                for(var i = 0; i < visibility.length; i++){

                    var _date = moment(visibility[i]);

                    if(_date.format('YYYY-MM') == moment(date).format('YYYY-MM')){
                        return true;
                    }

                }

                return false;
            },

            spinner : {
                show: function () {
                    spinner.spin(spinner_container);
                },
                hide: function(){
                    spinner.stop()
                }
            },

            controls : {
                enable : function(){
                    $controls.find('.btn').removeClass('disabled');
                },

                disable: function(){
                    $controls.find('.btn').addClass('disabled');
                }
            },

            update_url : function(url){

                history.pushState({}, null, '/negocios/forecast/'+url);

            }

        };

        // =========================== Init ===========================

        Sortable.create($actions_bar_trash[0], props);
        Sortable.create($actions_bar_lost[0], props);
        Sortable.create($actions_bar_won[0], props);

        for(var i = 1; i<=num_cols; i++){
            var $deals_container = $('#deals-container-' + i);
            updateStatusDeal.updateHeader(i);
            sortables.push( Sortable.create($deals_container[0], props) );
        }

    } // End if

});