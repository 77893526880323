var CKEDITOR_BASEPATH = base_url+'/ckeditor/';

// =================== Ajax setup ===================
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


var crm_config = {

    pikaday_i18n_config : {

        previousMonth : 'Mes anterior',
        nextMonth     : 'Mes siguiente',
        months        : ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
        weekdays      : ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sabado'],
        weekdaysShort : ['D','L','M','M','J','V','S']

    }


};
