$(document).ready(function(){

    var $deals = $('.deals');
    var $expected_close_date = $('#expected_close_date');
    var url_rates = base_url+'/tarifas/get-rate';
    var deal_value_mxn = 0;
    var deal_value_usd = 0;
    var $exchange_rate = $('#exchange_rate');
    var exchange_rate_init =  $exchange_rate.val();
    var exchange_rate = $exchange_rate.val();

    if($deals.length > 0 && $expected_close_date.length > 0){

        var room_types = [
            'sgl',
            'sgl_usd',
            'dbl',
            'dbl_usd',
            'tpl',
            'tpl_usd',
            'qdpl',
            'qdpl_usd'
        ];

        var rates = [
            'sgl',
            'dbl',
            'tpl',
            'qdpl'
        ];

        var rates_usd = [
            'sgl_usd',
            'dbl_usd',
            'tpl_usd',
            'qdpl_usd'
        ];

        var bkp_usd_rate_values = [];

        var hotel_id = 0;
        var $hotel = $('#hotel');
        var $rate_value = $('.rate-value');
        var $rooms_num = $('.rooms-num');
        var $alert_modal = $('#alert-modal');

        $hotel.on('change', function(){

            hotel_id = $(this).val();
            getRates();

        });

        $rate_value.on('keyup keydown change', function(e){

            calcRoomNights();
            calcDealValue();
            calcDealValueUSD();
            checkDealValue();
            calcUSDRates();

        });

        $exchange_rate.on('keyup keydown change', function(e){

            exchange_rate = $(this).val();
            recalcUSDRates();
            calcDealValue();
            calcDealValueUSD();
            checkDealValue();

        });

        // Calculador de cuartos/noche
        var $num_rooms = $('#num_rooms');
        var $room_nights = $('#room_nights');
        var $average_rate = $('#average_rate');
        var nights = $room_nights.val() != '' ? parseInt($room_nights.val())/parseInt($num_rooms.val()) : 1 ;
        var $caculator_date_start_input = $('#caculator_date_start');
        var $caculator_date_end_input = $('#caculator_date_end');
        var caculator_startDate;
        var caculator_endDate;

        var caculatorUpdateStartDate = function() {
            caculatorStartPicker.setStartRange(caculator_startDate);
            var end_date = moment(caculator_startDate).add(1,'days');
            caculatorEndPicker.setStartRange(end_date.toDate());
            caculatorEndPicker.setMinDate(end_date.toDate());
        };

        var caculatorUpdateEndDate = function() {
            //caculatorStartPicker.setEndRange(caculator_endDate);
            //caculatorStartPicker.setMaxDate(caculator_endDate);
            caculatorEndPicker.setEndRange(caculator_endDate);
        };

        var caculatorStartPicker = new Pikaday({
            field: $caculator_date_start_input[0],
            format: 'D/MM/YYYY',
            position: 'bottom left',
            theme: 'triangle-theme',
            i18n: crm_config.pikaday_i18n_config,
            onSelect: function() {
                caculator_startDate = this.getDate();
                caculatorUpdateStartDate();
                $('input[name=arrival]').val(moment(caculator_startDate).format('YYYY-MM-DD'));
                $caculator_date_end_input.click();
            },
            onOpen: function() {

                var position = $caculator_date_start_input.offset();

                $('.pika-single').css({
                    top: (position.top - ($('.pika-single').height()+10))
                });
            }
        });

        var caculatorEndPicker = new Pikaday({
            field: $caculator_date_end_input[0],
            format: 'D/MM/YYYY',
            position: 'bottom left',
            theme: 'triangle-theme',
            i18n: crm_config.pikaday_i18n_config,
            onSelect: function() {
                caculator_endDate = this.getDate();
                caculatorUpdateEndDate();

                var from = moment(caculator_startDate, 'YYYY-MM-DD');
                var to = moment(caculator_endDate, 'YYYY-MM-DD');

                nights = parseInt(to.diff(from, 'days'));

                calcRoomNights();
                calcDealValue();
                calcDealValueUSD();
                checkDealValue();
                clearRatesFields();

                $('input[name=departure]').val(moment(caculator_endDate).format('YYYY-MM-DD'));

                getRates();

            },
            onOpen: function() {

                var position = $caculator_date_end_input.offset();

                $('.pika-single').css({
                    top: (position.top - ($('.pika-single').height()+10))
                });
            }
        });

        var getRates = function(){

            if(hotel_id === 0){
                showModal('Por favor seleccione un hotel.');
                console.log('Por favor seleccione un hotel.');
                $caculator_date_end_input.val('');
                return;
            }

            var data = {
                hotel_id: hotel_id,
                start: $('input[name=arrival]').val(),
                end: $('input[name=departure]').val()
            };

            $.get(url_rates, data, function(data){

                if(data.length > 0){
                    $.each(data[0].rates, function(k, v){

                        if(k.indexOf('_usd') != -1){
                            if(v === null){
                                var key = k.replace('_usd','');
                                $('#'+k).val(accounting.formatMoney(data[0].rates[key]/exchange_rate, '', 2));
                                console.log(accounting.formatMoney(data[0].rates[key]/exchange_rate, '', 2));
                            }else{
                                $('#'+k).val(v);
                            }
                        }else{
                            $('#'+k).val(v);
                        }

                        //$('#'+k).val(v);

                        //$('#'+k+'_usd').val(accounting.formatMoney(v/exchange_rate, '', 2));

                    });
                }else{

                    showModal('No se han encontrado tarifas en la fecha seleccionada, por favor, ingréselas manualmente.');

                }

            });

        };

        var calcRoomNights = function(){

            var res = $num_rooms.val() * (!isNaN(nights) ? nights : 0);

            $room_nights.val(res);

        };

        var calcDealValue = function(){

            var rates_total = 0;

            $.each(room_types, function(i, item){

                rates_total += ((parseFloat($('#'+item).val()) || 0) * (parseInt($('#num_'+item+'_rooms').val()) || 0)) * (!isNaN(nights) ? nights : 0);

            });

            var res = (rates_total).toFixed(2);

            deal_value_mxn = res;

            $('#value').val(res);

        };

        var calcDealValueUSD = function(){

            var rates_total = 0;

            $.each(room_types, function(i, item){

                rates_total += ((parseFloat($('#'+item+'_usd').val()) || 0) * (parseInt($('#num_'+item+'_rooms').val()) || 0)) * (!isNaN(nights) ? nights : 0);

            });

            var res = deal_value_usd = (rates_total).toFixed(2);
            //var res_exchangre = ($('#value').val()/exchange_rate).toFixed(2);

            $('#value_usd').val(res);
            $('#deal_value_label').html('Valor del negocio ($'+res+' USD)');

        };

        var checkDealValue = function(){

            var calc = 0.00;

            if(deal_value_mxn == 0 || deal_value_mxn == 0.00){
                calc = deal_value_usd * exchange_rate;
                $('#value').val(calc);
                $('#deal_value_label').html('Valor del negocio ($'+deal_value_usd+' USD)');
            }

            if(deal_value_usd == 0 ||deal_value_usd == 0.00){
                calc = (deal_value_mxn/exchange_rate).toFixed(2);
                $('#deal_value_label').html('Valor del negocio ($'+calc+' USD)');
                $('#value_usd').val(calc);

            }

        };

        var calcUSDRates = function(){

             $.each(rates, function(i, item){

                 var $item_mxn = $('#'+item);
                 var $item_usd = $('#'+item+'_usd');
                 var current = $item_mxn.val();
                 $item_usd.val(accounting.formatMoney(current/exchange_rate, '', 2));

            });

        };

        var recalcUSDRates = function(){

             $.each(rates_usd, function(i, item){

                 var $item = $('#'+item);
                 var current = $item.val();
                 var neutral = current*exchange_rate_init;
                 bkp_usd_rate_values.push(neutral);
                 $item.val(accounting.formatMoney(neutral/exchange_rate, '', 2));

            });

        };

        var clearRatesFields = function(){

            $.each(room_types, function(i, item){

                $('#'+item).val('');

            });

        };

        var showModal = function(txt){

            $alert_modal
                .find('.modal-body')
                .html(txt);

            $alert_modal.modal('show');

        };

        $rooms_num.on('keyup keydown change', function(e){

            var total = 0;

            $.each($rooms_num, function(i, v){

                total += parseInt($(this).val()) || 0;

                $num_rooms.val(total);

            });

            calcRoomNights();
            calcDealValue();
            calcDealValueUSD();
            checkDealValue();

        });

        $average_rate.on('keyup keydown change', function(e){

            calcRoomNights();
            calcDealValue();
            calcDealValueUSD();
            checkDealValue();

        });

        caculatorStartPicker.getDate();
        caculatorEndPicker.getDate();

        caculatorUpdateStartDate();
        caculatorUpdateEndDate();

        // Fecha de cierre
        new Pikaday({

            field: $expected_close_date[0],
            minDate: moment().toDate(),
            i18n: crm_config.pikaday_i18n_config,
            format: 'D/MM/YYYY',
            position: 'top left',
            theme: 'triangle-theme',
            onOpen: function() {

                var position = $expected_close_date.offset();

                $('.pika-single').css({
                    top: (position.top - ($('.pika-single').height()+10))
                });
            },
            onSelect: function(date){

                $('#expected_close_date_hidden').val(moment(date).format('YYYY-MM-DD'));

            }

        });

    }

    $('.btn-mxn').on('click', function(){

        $('.btn-usd').removeClass('active');
        $(this).addClass('active');

        $('.usd').hide();
        $('.mxn').show();

    });

    $('.btn-usd').on('click', function(){

        $('.btn-mxn').removeClass('active');
        $(this).addClass('active');

        $('.mxn').hide();
        $('.usd').show();

    });

});